import { handleActions } from 'redux-actions';

import {
  putBatchFlow,
  putBatchFlowList,
  updateBatchFlow,
  putTriggers,
} from '../actions/batchFlow';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
  blocks: [],
};

export default handleActions(
  {
    [putBatchFlow]: (state, { payload }) => (
      {
        ...state,
        editedItem: payload,
      }
    ),
    [putBatchFlowList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
    [updateBatchFlow]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload },
    }),
    [putTriggers]: (state, { payload }) => ({
      ...state,
      triggers: payload,
    }),
  },
  INITIAL_STATE,
);
