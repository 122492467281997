/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const BatchFlow = ({ fillColor, ...props }) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-workflow">
    <rect width="8" height="8" x="3" y="3" rx="2" />
    <path d="M7 11v4a2 2 0 0 0 2 2h4" />
    <rect width="8" height="8" x="13" y="13" rx="2" />
  </svg>
);

BatchFlow.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default BatchFlow;
