/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const AnalyticsIcon = ({ fillColor, ...props }) => (
  <svg width="17.301" height="17.301" {...props}>
    <path
      d="M13.65 16.65a.945.945 0 0 1-1-1v-9a.945.945 0 0 1 1-1h2a.945.945 0 0 1 1 1v9a.945.945 0 0 1-1 1zm-6 0a.944.944 0 0 1-1-1v-14a.945.945 0 0 1 1-1h2a.945.945 0 0 1 1 1v14a.944.944 0 0 1-1 1zm-6 0a.945.945 0 0 1-1-1v-4a.945.945 0 0 1 1-1h2a.946.946 0 0 1 1 1v4a.945.945 0 0 1-1 1z"
      fill="none"
      stroke={fillColor}
      strokeWidth="1.3px"
    />
  </svg>
);

AnalyticsIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default AnalyticsIcon;
