import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_BATCH_FLOW = '@batch-flow/CREATE_BATCH_FLOW';
const FETCH_BATCH_FLOW = '@batch-flow/FETCH_BATCH_FLOW';
const FETCH_BATCH_FLOW_STAT = '@batch-flow/FETCH_BATCH_FLOW_STAT';
const PUT_BATCH_FLOW = '@batch-flow/PUT_BATCH_FLOW';
const UPDATE_BATCH_FLOW = '@batch-flow/UPDATE_BATCH_FLOW';
const HOLD_BATCH_FLOW = '@batch-flow/HOLD_BATCH_FLOW';
const ACTIVATE_BATCH_FLOW = '@batch-flow/ACTIVATE_BATCH_FLOW';
const FETCH_BATCH_FLOW_LIST = '@batch-flow/FETCH_BATCH_FLOW_LIST';
const PUT_BATCH_FLOW_LIST = '@batch-flow/PUT_BATCH_FLOW_LIST';
const PUT_BATCH_FLOW_CAMPAIGN_NAMES = '@batch-flow/PUT_CAMPAIGN_NAMES';
const VALIDATE_BATCH_FLOW_CAMPAIGN_NAME = '@batch-flow/VALIDATE_CAMPAIGN_NAME';
const PUT_BATCH_FLOW_VISITORS = '@batch-flow/PUT_VISITORS';
const FETCH_TRIGGERS = '@batch-flow/FETCH_TRIGGERS';
const PUT_TRIGGERS = '@batch-flow/PUT_TRIGGERS';

export const createBatchFlowRoutine = createRoutine(CREATE_BATCH_FLOW);
export const createBatchFlowHandler = bindRoutineToReduxForm(
  createBatchFlowRoutine,
);

export const activateBatchFlow = createAction(ACTIVATE_BATCH_FLOW);
export const holdBatchFlow = createAction(HOLD_BATCH_FLOW);

export const fetchBatchFlow = createAction(FETCH_BATCH_FLOW);
export const putBatchFlow = createAction(PUT_BATCH_FLOW);
export const updateBatchFlow = createAction(UPDATE_BATCH_FLOW);
export const fetchDownloadStat = createAction(FETCH_BATCH_FLOW_STAT);

export const fetchBatchFlowList = createAction(FETCH_BATCH_FLOW_LIST);
export const putBatchFlowList = createAction(PUT_BATCH_FLOW_LIST);

export const putVisitors = createAction(PUT_BATCH_FLOW_VISITORS);

export const putCampaignNames = createAction(PUT_BATCH_FLOW_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_BATCH_FLOW_CAMPAIGN_NAME,
);

export const fetchTriggers = createAction(FETCH_TRIGGERS);
export const putTriggers = createAction(PUT_TRIGGERS);
