import { handleActions } from 'redux-actions';
import { putOnsite, putOnsiteList, updateOnsite, putTemplateList } from '../actions/onsite';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null
};

export default handleActions(
  {
    [putOnsite]: (state, { payload }) => {
      return { ...state, editedItem: payload }
    },
    [putOnsiteList]: (state, { payload: { list, total, append } }) => ({
      ...state,
      list: append ? [...state.list, ...list] : list,
      total,
    }),
    [updateOnsite]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload }
    }),
    [putTemplateList]: (state, { payload: templateList }) => {
      return {
        ...state,
        templateList
      };
    }
  },
  INITIAL_STATE
);
