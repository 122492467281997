import { handleActions } from 'redux-actions';

import {
  putEmail,
  putEmailList,
  updateEmail,
  putTemplateList,
} from '../actions/email';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
};

export default handleActions(
  {
    [putEmail]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putEmailList]: (state, { payload: { list, total, append } }) => ({
      ...state,
      list: append ? [...state.list, ...list] : list,
      total,
    }),
    [updateEmail]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload },
    }),
    [putTemplateList]: (state, { payload: templateList }) => ({
      ...state,
      templateList,
    }),
  },
  INITIAL_STATE,
);
