import { handleActions } from 'redux-actions';

import { putSms, putSmsList, updateSms } from '../actions/sms';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
};

export default handleActions(
  {
    [putSms]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putSmsList]: (state, { payload: { list, total, append } }) => ({
      ...state,
      list: append ? [...state.list, ...list] : list,
      total,
    }),
    [updateSms]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload },
    }),
  },
  INITIAL_STATE,
);
