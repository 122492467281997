import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { sms as api } from '../api';
import {
  mapFormValuesToSms,
  mapSmsToFormValues,
} from '../mappers/smsMappers';
import { sms as smsActions, snackbar as snackbarActions } from '../redux/actions';

function* createSms({ payload: { values } }) {
  try {
    yield put(smsActions.createSmsRoutine.request());

    const [transformedValues] = mapFormValuesToSms(values);
    const { id } = transformedValues;
    const data = new FormData();

    data.append('form', JSON.stringify(transformedValues));
    const sms = yield call(api.postSms, id, data);

    yield put(smsActions.createSmsRoutine.success(sms));
  } catch (error) {
    yield put(smsActions.createSmsRoutine.failure(error));
  } finally {
    yield put(smsActions.createSmsRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateSms({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusSms, id, STATUSES.active);
    yield put(
      smsActions.fetchSmsList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdSms({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusSms, id, STATUSES.onHold);
    yield put(
      smsActions.fetchSmsList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Hold sms error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSms({ payload: smsId }) {
  try {
    const sms = yield call(api.fetchSms, smsId);

    yield put(smsActions.putSms(mapSmsToFormValues(sms)));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchVisitors({ payload: { fields, formName } }) {
  try {
    yield delay(1000);
    const result = yield call(api.fetchVisitors, fields);

    yield put(smsActions.putVisitors(result));
  } catch (error) {}
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchSmsList({
  payload: {
    page, perPage, status, search, append,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchSmsList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(smsActions.putSmsList({ list: mapStatus(list), total, append }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms list error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCampaignNames, name);

    yield put(smsActions.putCampaignNames(result));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms current campaign names error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

export default function* watcher() {
  yield takeLatest(smsActions.createSmsRoutine.TRIGGER, createSms);
  yield takeLatest(smsActions.activateSms.toString(), activateSms);
  yield takeLatest(smsActions.holdSms.toString(), holdSms);
  yield takeLatest(smsActions.fetchSms.toString(), fetchSms);
  yield takeLatest(smsActions.fetchVisitors.toString(), fetchVisitors);
  yield takeLatest(
    smsActions.fetchSmsList.toString(),
    fetchSmsList,
  );
  yield takeLatest(
    smsActions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames,
  );
  yield takeLatest(
    smsActions.validateCampaignName.toString(),
    validateCampaignName,
  );
}
