import { handleActions, combineActions } from 'redux-actions';
import {
    putCountries,
    putRegions,
    putCities,
    putOperators,
    putTariffs,
    resetFieldsData,
    putMnos,
    putTags,
    putCompiledScripts,
    putLanguages,
    putEmailCampaigns,
    putGeoConfig,
    putSmsCampaigns,
    putTargetLanguages,
    putPromoCodeList,
    putShortUrl, 
    putOfferCampaigns,
} from '../actions/fields';
import {
  onsite as onsiteActions,
  webPush as webPushActions,
  email as emailActions,
  sms as smsActions,
  smsTree as smsTreeActions,
  chatBot as chatBotActions,
  batchFlow as batchFlowActions,
  socialSharing as socialSharingActions,
  cpa as cpaActions
} from '../actions';

const INITIAL_STATE = {
  visitors: 0,
  countries: {},
  regions: {},
  tags: [],
  compiledScripts: [],
  cities: {},
  geoConfig: {},
  operators: {},
  tariffs: {},
  shortUrl: null,
  emailCampaigns: {},
  promoCodeList: {},
  smsCampaigns: {},
  offerCampaigns: {},
  languages: {},
  targetLanguages: {},
  campaignNames: {}
};

export default handleActions(
  {
    [putCountries]: (state, { payload: { countries } }) => ({
      ...state,
      countries
    }),
    [putRegions]: (state, { payload: { countryId, regions } }) => ({
      ...state,
      regions: {
        ...state.regions,
        [countryId]: regions
      }
    }),
    [putCities]: (state, { payload: { countryId, regionId, cities } }) => ({
      ...state,
      cities: {
        ...state.cities,
        [countryId]: {
          ...state.cities[countryId],
          [regionId]: cities
        }
      }
    }),
    [putGeoConfig]: (state, { payload }) => ({
      ...state,
        geoConfig: payload
    }),
    [putOperators]: (state, { payload }) => ({
      ...state,
      operators: { ...state.operators, ...payload }
    }),
    [putTariffs]: (state, { payload }) => ({
      ...state,
        tariffs: { ...state.tariffs, ...payload }
    }),
    [putLanguages]: (state, { payload }) => ({
      ...state,
        languages: { ...state.languages, ...payload }
    }),
    [putTargetLanguages]: (state, { payload: list }) => ({
      ...state,
        targetLanguages: list
    }),
    [putMnos]: (state, { payload }) => ({
      ...state,
      mnos: payload
    }),
    [putTags]: (state, { payload: list }) => ({
      ...state,
      tags: list
    }),
    [putShortUrl]: (state, { payload: url }) => ({
      ...state,
      shortUrl: url
    }),
    [putEmailCampaigns]: (state, { payload: list }) => ({
      ...state,
      emailCampaigns: list
    }),
    [putSmsCampaigns]: (state, { payload: list }) => ({
      ...state,
      smsCampaigns: list
    }),
    [putOfferCampaigns]: (state, { payload: list }) => ({
      ...state,
      offerCampaigns: list
    }),
    [putPromoCodeList]: (state, { payload: list }) => ({
      ...state,
      promoCodeList: list
    }),
    [putCompiledScripts]: (state, { payload: list }) => ({
      ...state,
      compiledScripts: list
    }),
    [combineActions(
      webPushActions.putCampaignNames,
      emailActions.putCampaignNames,
      smsActions.putCampaignNames,
      smsTreeActions.putCampaignNames,
      chatBotActions.putCampaignNames,
      batchFlowActions.putCampaignNames,
      onsiteActions.putCampaignNames,
      socialSharingActions.putCampaignNames,
      cpaActions.putCampaignNames
    )]: (state, { payload }) => ({
      ...state,
      campaignNames: payload
    }),
    [combineActions(
      webPushActions.putVisitors,
      emailActions.putVisitors,
      onsiteActions.putVisitors,
      smsActions.putVisitors,
      socialSharingActions.putVisitors
    )]: (state, { payload }) => ({ ...state, visitors: payload }),
    [resetFieldsData]: () => INITIAL_STATE
  },
  INITIAL_STATE
);
