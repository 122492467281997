export const ROUTE_PUSH_NEW_WEB_PUSH = '/push-create-push';
export const ROUTE_PUSH_EDIT_WEB_PUSH = '/push-edit-push';
export const ROUTE_PUSH_MY_WEB_PUSH_CAMPAIGNS = '/push-my-web-push-campaigns';

export const ROUTE_EMAIL_NEW_EMAIL = '/email-create-email';
export const ROUTE_EMAIL_EDIT_EMAIL = '/email-edit-email';
export const ROUTE_EMAIL_MY_EMAIL_CAMPAIGNS = '/email-my-campaigns';

export const ROUTE_CAMPAIGN_OFFER_NEW_OFFER = '/offers-create-offer-campaign';
export const ROUTE_CAMPAIGN_OFFER_EDIT_OFFER = '/offers-edit-offer-campaign';
export const ROUTE_CAMPAIGN_OFFER_MY_OFFER_CAMPAIGNS = '/offers-my-campaigns';

export const ROUTE_SMS_NEW_SMS = '/sms-create-sms';
export const ROUTE_SMS_EDIT_SMS = '/sms-edit-sms';
export const ROUTE_SMS_MY_SMS_CAMPAIGNS = '/sms-my-campaigns';

export const ROUTE_BATCH_FLOW_MY_BATCH_FLOW = '/batch-flow-my-campaigns';
export const ROUTE_BATCH_FLOW_NEW_BATCH_FLOW = '/batch-flow-new-campaign';
export const ROUTE_BATCH_FLOW_EDIT_BATCH_FLOW = '/batch-flow-edit-campaigns';

export const ROUTE_SMS_TREE_NEW_SMS = '/sms-tree-create-sms';
export const ROUTE_SMS_TREE_EDIT_SMS = '/sms-tree-edit-sms';
export const ROUTE_SMS_TREE_MY_SMS_CAMPAIGNS = '/sms-tree-my-campaigns';

export const ROUTE_CHAT_BOT_NEW_CHAT = '/chat-bot-create-chat-bot';
export const ROUTE_CHAT_BOT_EDIT_CHAT = '/chat-bot-edit-chat-bot';
export const ROUTE_CHAT_BOT_MY_CHAT_CAMPAIGNS = '/chat-bot-my-campaigns';

export const ROUTE_ONSITE_NEW_ONSITE = '/onsite-create-onsite-campaign';
export const ROUTE_ONSITE_EDIT_ONSITE = '/onsite-edit-onsite-campaign';
export const ROUTE_ONSITE_MY_ONSITE_CAMPAIGNS = '/onsite-my-onsite-campaigns';

export const ROUTE_ONSITE_NEXT_NEW_ONSITE = '/onsite-next-create-campaign';
export const ROUTE_ONSITE_NEXT_EDIT_ONSITE = '/onsite-next-edit-campaign';
export const ROUTE_ONSITE_NEXT_MY_ONSITE_CAMPAIGNS = '/onsite-next-my-campaigns';

export const ROUTE_SHARING_NEW_CAMPAIGN = '/sharing-new-campaign';
export const ROUTE_SHARING_EDIT_CAMPAIGN = '/sharing-edit-campaign';
export const ROUTE_SHARING_MY_CAMPAIGNS = '/sharing-my-campaigns';

export const ROUTE_CPA_LOYALTY_NEW_CAMPAIGN = '/cpa-platform-new-cpa-loyalty';
export const ROUTE_CPA_LOYALTY_EDIT_CAMPAIGN = '/cpa-platform-edit-cpa-loyalty';
export const ROUTE_CPA_MY_CAMPAIGNS = '/cpa-platform-my-campaigns';
export const ROUTE_CPA_MY_OFFERS = '/cpa-platform-my-offers';
export const ROUTE_CPA_MSISDN_STATISTICS = '/cpa-platform-msisdn-statistics';
export const ROUTE_CPA_MSISDN_LOGS = '/cpa-platform-msisdn-logs';

export const ROUTE_CPA_OFFER_NEW_OFFER = '/cpa-platform-new-cpa-offer';
export const ROUTE_CPA_OFFER_EDIT_OFFER = '/cpa-platform-edit-cpa-offer';

export const ROUTE_ANALYTICS = '/analytics-all';

export const ROUTE_METRICS = '/analytics-metrics';

export const ROUTE_CPA_WALL = '/bazar';

export const ROUTE_AUTHORIZATION = '/authorization';

export const ROUTE_OTP = '/otp';

export const ROUTE_ERROR = '/error';

export const ROUTE_PROMO_CODE_LIST_UPLOADS = '/promo-codes-my-uploads';
export const ROUTE_PROMO_CODE_NEW_UPLOAD = '/promo-codes-new-upload';
export const ROUTE_PROMO_CODE_EDIT_UPLOAD = '/promo-codes-edit-upload';

export const ROUTE_SIM_NUMBER_LIST_UPLOADS = '/sim-numbers-my-uploads';
export const ROUTE_SIM_NUMBER_NEW_UPLOAD = '/sim-numbers-new-upload';
export const ROUTE_SIM_NUMBER_EDIT_UPLOAD = '/sim-numbers-edit-upload';

export const ROUTE_SIM_POINTS_LIST_UPLOADS = '/sim-numbers-my-uploads-points';
export const ROUTE_SIM_POINTS_NEW_UPLOAD = '/sim-numbers-new-upload-points';
export const ROUTE_SIM_POINTS_EDIT_UPLOAD = '/sim-numbers-edit-upload-points';

export const ROUTE_SERVICES_LIST_UPLOADS = '/sim-numbers-my-uploads-services';
export const ROUTE_SERVICES_NEW_UPLOAD = '/sim-numbers-new-upload-services';
export const ROUTE_SERVICES_EDIT_UPLOAD = '/sim-numbers-edit-upload-services';

export const ROUTE_BLACK_LIST_UPLOADS = '/black-list-my-uploads';
export const ROUTE_BLACK_LIST_NEW_UPLOAD = '/black-list-new-upload';
export const ROUTE_BLACK_LIST_EDIT_UPLOAD = '/black-list-edit-upload';

export const ROUTE_CUSTOMERS = '/customers-list';
export const ROUTE_CUSTOMERS_DETAILS = '/customers-details';
