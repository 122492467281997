import {
  BATCH_FLOW_ENDPOINT,
  BATCH_FLOW_STATUS_ENDPOINT,
  BATCH_FLOW_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  BATCH_FLOW_INFO_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postBatchFlow = async (id, BatchFlowFormData) => {
  const { data: createdBatchFlow } = await axios.post(
    id ? `${BATCH_FLOW_ENDPOINT}/${id}` : BATCH_FLOW_ENDPOINT,
    BatchFlowFormData,
  );
  return createdBatchFlow;
};

const changeStatusBatchFlow = async (SmsId, setObjectStatus) => {
  const { data } = await axios.post(
    `${BATCH_FLOW_STATUS_ENDPOINT}/${SmsId}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchBatchFlow = async (BatchFlowId) => {
  const { data: BatchFlow } = await axios.get(
    `${BATCH_FLOW_ENDPOINT}/${BatchFlowId}`,
  );

  return BatchFlow;
};

const fetchBatchFlowList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${BATCH_FLOW_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchDownloadStat = async (id) => {
  await axios.get(`${BATCH_FLOW_INFO_ENDPOINT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_stat_more.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${BATCH_FLOW_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const fetchTriggers = async () => {
  const { data } = await axios.get(
    '/modules/triggers',
  );

  return data;
};

export default {
  postBatchFlow,
  fetchBatchFlow,
  fetchBatchFlowList,
  fetchDownloadStat,
  changeStatusBatchFlow,
  validateCampaignName,
  fetchTriggers,
};
