import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { batchFlow as api } from '../api';
import { batchFlow as batchFlowActions, snackbar as snackbarActions } from '../redux/actions';

function* createBatchFlow({ payload: { values } }) {
  try {
    yield put(batchFlowActions.createBatchFlowRoutine.request());

    const { campaign_name, settings, id } = values;

    const data = new FormData();

    data.append('form', JSON.stringify({ campaign_name, settings }));

    const batchFlow = yield call(api.postBatchFlow, id, data);

    yield put(batchFlowActions.createBatchFlowRoutine.success(batchFlow));
  } catch (error) {
    yield put(batchFlowActions.createBatchFlowRoutine.failure(error));
  } finally {
    yield put(batchFlowActions.createBatchFlowRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateBatchFlow({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusBatchFlow, id, STATUSES.active);
    yield put(
      batchFlowActions.fetchBatchFlowList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdBatchFlow({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusBatchFlow, id, STATUSES.onHold);
    yield put(
      batchFlowActions.fetchBatchFlowList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {}
}

function* fetchBatchFlow({ payload: batchFlowId }) {
  try {
    const batchFlow = yield call(api.fetchBatchFlow, batchFlowId);
    yield put(batchFlowActions.putBatchFlow(batchFlow));
  } catch (error) {}
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchBatchFlowList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchBatchFlowList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(batchFlowActions.putBatchFlowList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

function* fetchDownloadStat({payload}) {
  try {
    yield call(api.fetchDownloadStat, payload);
  } catch (error) {
    console.error(error);
  }
}

function* fetchTriggers() {
  try {
    const triggers = yield call(api.fetchTriggers);
    yield put(batchFlowActions.putTriggers(triggers));
  } catch (error) {
    console.error(error);
  }
}

export default function* watcher() {
  yield takeLatest(batchFlowActions.createBatchFlowRoutine.TRIGGER, createBatchFlow);
  yield takeLatest(batchFlowActions.activateBatchFlow.toString(), activateBatchFlow);
  yield takeLatest(batchFlowActions.holdBatchFlow.toString(), holdBatchFlow);
  yield takeLatest(batchFlowActions.fetchBatchFlow.toString(), fetchBatchFlow);
  yield takeLatest(batchFlowActions.fetchTriggers.toString(), fetchTriggers);
  yield takeLatest(batchFlowActions.fetchDownloadStat.toString(), fetchDownloadStat);
  yield takeLatest(
    batchFlowActions.fetchBatchFlowList.toString(),
    fetchBatchFlowList,
  );
  yield takeLatest(
    batchFlowActions.validateCampaignName.toString(),
    validateCampaignName,
  );
}
