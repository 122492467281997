export const NEW_ONSITE_FORM_NAME = 'newOnsite';
export const EDIT_ONSITE_FORM_NAME = 'editOnsite';

export const NEW_WEP_PUSH_FORM_NAME = 'newWepPush';
export const EDIT_WEP_PUSH_FORM_NAME = 'editWepPush';

export const NEW_EMAIL_FORM_NAME = 'newEmail';
export const EDIT_EMAIL_FORM_NAME = 'editEmail';

export const NEW_CAMPAIGN_OFFER_FORM_NAME = 'newCampaignOffer';
export const EDIT_CAMPAIGN_OFFER_FORM_NAME = 'editCampaignOffer';

export const NEW_SMS_FORM_NAME = 'newSms';
export const EDIT_SMS_FORM_NAME = 'editSms';

export const NEW_SMS_TREE_FORM_NAME = 'newSmsTree';
export const EDIT_SMS_TREE_FORM_NAME = 'editSmsTree';

export const CHAT_BOT_FORM_NAME = 'newChatBot';
export const EDIT_CHAT_BOT_FORM_NAME = 'editChatBot';

export const NEW_SOCIAL_SHARING_FORM_NAME = 'newSocialSharing';
export const EDIT_SOCIAL_SHARING_FORM_NAME = 'EditSocialSharing';

export const NEW_CPA_LOYALTY_FORM_NAME = 'newCpaLoyalty';
export const EDIT_CPA_LOYALTY_FORM_NAME = 'editCpaLoyalty';

export const NEW_CPA_OFFER_FORM_NAME = 'newCpaOffer';
export const EDIT_CPA_OFFER_FORM_NAME = 'editCpaOffer';

export const NEW_PROMO_CODE_UPLOADS_FORM_NAME = 'newPromoCodeUploads';
export const EDIT_PROMO_CODE_UPLOADS_FORM_NAME = 'editPromoCodeUploads';

export const NEW_BATCH_FLOW_FORM_NAME = 'newBatchFlow';
export const EDIT_BATCH_FLOW_FORM_NAME = 'editBatchFlow';

export const NEW_SIM_NUMBER_UPLOADS_FORM_NAME = 'newSimNumberUploads';
export const EDIT_SIM_NUMBER_UPLOADS_FORM_NAME = 'editSimNumberUploads';

export const NEW_BLACK_LIST_UPLOADS_FORM_NAME = 'newBlackListUploads';
export const EDIT_BLACK_LIST_UPLOADS_FORM_NAME = 'editBlackListUploads';

export const NEW_SIM_NUMBER_POINTS_FORM_NAME = 'newSimPointsUploads';
export const EDIT_SIM_NUMBER_POINTS_FORM_NAME = 'editSimPointsUploads';

export const NEW_SERVICES_FORM_NAME = 'newServicesUploads';
export const EDIT_SERVICES_FORM_NAME = 'editServicesUploads';

export const WEP_PUSH_STEPS = {
  [NEW_WEP_PUSH_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_WEP_PUSH_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const EMAIL_STEPS = {
  [NEW_EMAIL_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_EMAIL_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const OFFER_CAMPAIGNS_STEPS = {
  [NEW_CAMPAIGN_OFFER_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_CAMPAIGN_OFFER_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const SMS_STEPS = {
  [NEW_SMS_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_SMS_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const CHAT_BOT_STEPS = {
  [CHAT_BOT_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_CHAT_BOT_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const SMS_TREE_STEPS = {
  [NEW_SMS_TREE_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_SMS_TREE_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const ONSITE_STEPS = {
  [NEW_ONSITE_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
  [EDIT_ONSITE_FORM_NAME]: ['Trigger', 'Design', 'Bonus'],
};

export const SOCIAL_SHARING_STEPS = {
  [NEW_SOCIAL_SHARING_FORM_NAME]: ['Trigger', 'Design', 'Social media post', 'Bonus'],
  [EDIT_SOCIAL_SHARING_FORM_NAME]: ['Trigger', 'Design', 'Social media post', 'Bonus'],
};

export const CPA_LOYALTY_STEPS = {
  [NEW_CPA_LOYALTY_FORM_NAME]: ['Design', 'Block Offer List'],
  [EDIT_CPA_LOYALTY_FORM_NAME]: ['Design', 'Block Offer List'],
};

export const CPA_OFFER_STEPS = {
  [NEW_CPA_OFFER_FORM_NAME]: ['Targets', 'Bonus', 'Offer Card Design'],
  [EDIT_CPA_OFFER_FORM_NAME]: ['Targets', 'Bonus', 'Offer Card Design'],
};

export const PROMO_CODE_UPLOADS_STEPS = {
  [NEW_PROMO_CODE_UPLOADS_FORM_NAME]: ['Name', 'File'],
  [EDIT_PROMO_CODE_UPLOADS_FORM_NAME]: ['Name', 'File'],
};

export const BATCH_FLOW_STEPS = {
  [NEW_BATCH_FLOW_FORM_NAME]: ['Name', 'Design'],
  [EDIT_BATCH_FLOW_FORM_NAME]: ['Name', 'Design'],
};

export const SIM_NUMBER_UPLOADS_STEPS = {
  [NEW_SIM_NUMBER_UPLOADS_FORM_NAME]: ['Name', 'File'],
  [EDIT_SIM_NUMBER_UPLOADS_FORM_NAME]: ['Name', 'File'],
};

export const BLACK_LIST_UPLOADS_STEPS = {
  [NEW_BLACK_LIST_UPLOADS_FORM_NAME]: ['Name', 'File'],
  [EDIT_BLACK_LIST_UPLOADS_FORM_NAME]: ['Name', 'File'],
};

export const SIM_NUMBER_POINTS_STEPS = {
  [NEW_SIM_NUMBER_POINTS_FORM_NAME]: ['Name', 'File'],
  [EDIT_SIM_NUMBER_POINTS_FORM_NAME]: ['Name', 'File'],
};

export const SERVICES_STEPS = {
  [NEW_SERVICES_FORM_NAME]: ['Name', 'File'],
  [EDIT_SERVICES_FORM_NAME]: ['Name', 'File'],
};

export const WIZARAD_FORM_CONFIG = {
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
};

/**
 * @typedef {object} FormSchemaProperties
 * @property {string} events
 * @property {string} items           - items in first column
 * @property {string} items2          - items in second column
 * @property {object} elementsOptions - additional elements prop, saved in backend
 */

/**
 * @typedef {object} FormSchema
 * @property {string} name
 * @property {FormSchemaProperties} properties
 */

/**
 * @type {FormSchema}
 */
export const FORM_WIDGET_SCHEMA = {
  name: 'editedWidget',

  editedWidget: 'editedWidget',

  properties: {
    events: 'events',
    items: 'items',
    items2: 'items2',
    elementsOptions: 'elementsOptions',
  },
};

export const FORM_WIDGET_ELEMENT_CHILDREN_KEY = 'childrenElements';
